<template>
  <div>
    <Preloader></Preloader>
    <header id="nav">
      <Menu />
    </header>
    <main>
      <router-view />
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
</template>
<script>
import Preloader from '@/components/Preloader.vue'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Preloader,
    Menu,
    Footer
  },
  methods: {
    onResize(){
      this.$store.commit('windowWidth', window.innerWidth)
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  },
  mounted(){
    if (this.isLoggedIn) {
      this.$store.dispatch('getUsuario')
    }
    window.addEventListener('resize', this.onResize)
  },
  unmounted(){
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    isLoggedIn(){
      return this.$store.getters.isLoggedIn
    },
  }
}
</script>
<style lang="scss">
@import './assets/styles/app.scss';
</style>
