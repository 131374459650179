/*import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
require('bootstrap')

createApp(App).use(store).use(router).use(store).mount('#app')
*/

//import Vue from 'vue'
import { createApp } from 'vue'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
window.$ = window.jQuery = require('jquery')
require('jquery-confirm')
require('bootstrap')
import {initialize} from './store/helpers'
window.axios = require('axios')
import { useToast, POSITION } from "vue-toastification"
import 'vue-toastification/dist/index.css'
var moment = require('moment')

const app = createApp(App)
const router = createRouter()
const store = createStore(window.axios)

sync(store, router)
initialize(store, router, window.axios)

app.use(router).use(store)

app.config.globalProperties.$toast = useToast({
	position: POSITION.BOTTOM_CENTER
})

app.config.globalProperties.$filters = {
	currency(value, symbol=true){
		if (!value) return ''
		let num = (value/1).toFixed(2)
		let val = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
		if (symbol) {
			return 'Q.' + val
		}
		return val
	},
	date(value, format = 'DD-MM-YYYY'){
		if (!value) return ''
		return moment(value.toString()).format(format)
	},
	dateTime(value){
		if (!value) return ''
		return moment(value.toString()).format('DD-MM-YYYY HH:MM')
	}
}

app.mount('#app')