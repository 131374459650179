
<template>
  <div style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; z-index: 10000;" class="bg-light" v-if="!isloaded">
      <div style="position: absolute; left: 0px; top: 0px;">
        <img :src="imgLandingTopLeft" class="w-25 img-fluid">
      </div>
      <div style="position: absolute; right: 0px; top: 0px; text-align: right;">
        <img :src="imgLandingTopRight" class="w-25 img-fluid">
      </div>
      <div style="position: absolute; right: 0px; left: 0px; bottom: -64px;">
        <img :src="imgLandingBottom" class="img-fluid">
      </div>
      <div class="d-flex align-items-center justify-content-center h-100">
        <img :src="Preloader">
      </div>
    </div>
</template>
<script type="text/javascript">
  import Preloader from '@/assets/img/preloader.gif'
  import imgLandingBottom from '@/assets/img/backgrounds/landing-bottom.png'
  import imgLandingTopRight from '@/assets/img/backgrounds/landing-top-right.png'
  export default {
    data(){
      return {
        isloaded: false,
        Preloader,
        imgLandingBottom,
        imgLandingTopRight
      }
    },
    mounted(){
      document.onreadystatechange = () => {
        var mod = this
        if (document.readyState == "complete") {
          setTimeout(function(){
            mod.isloaded = true
          }, 50)
        }
      }
    },
  }
</script>