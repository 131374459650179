export function getTokenApi(Cookies) {
    const tokenStr = Cookies.get('token')

    if(!tokenStr) {
        return null
    }

    return tokenStr 
}

export function getItems(Cookies){
    const items = Cookies.get("items")

    if (items) {
        if (typeof items === 'string') {
            return JSON.parse(items)
        }

    }
    
    return []
}

export function initialize(store, router, axios) {
	router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        const token = store.state.token
        
        document.title = (to.meta.title || '') + (to.meta.title ? ' | ' : '') + 'Mesa de regalo'

        if ((to.path == '/inicio-sesion' || to.path == '/registro') && token) {
            next({name: 'home'})
        } else if (requiresAuth && !token) {
            next({name: 'login'})
        } else {
            next()
        }
        store.commit('errors', {})
    })

    axios.interceptors.response.use(null, (error) => {
        if (error.response) {
            if (error.response.status == 401) {
                store.commit('logout')
                if (router.history.current.path !== '/inicio-sesion') {
                    router.push({name: 'login'})
                }
            }
        }
        //showError(error)
        return Promise.reject(error)
    })
}