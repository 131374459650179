import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

const isServer = typeof window === 'undefined'

const history = isServer ? createMemoryHistory() : createWebHistory(process.env.BASE_URL)

const routes = [
	{ 
		path: '/', 
		name: 'home',
		component: () => import('../views/Home.vue')
	},
	{ 
		path: '/inicio-sesion', 
		name: 'login',
		component: () => import('../views/auth/InicioSesion.vue')
	},
	{
		path: '/cerrar-sesion',
		name: 'logout',
		component: () => import('../views/auth/CerrarSesion.vue')
	},
	{ 
		path: '/registro', 
		name: 'register',
		component: () => import('../views/auth/Registro.vue')
	},
	{
		path: '/mesas', 
		name: 'mesas.index',
		component: () => import('../views/mesas/Index.vue')
	},
	{
		path: '/como-funciona',
		name: 'como-funciona.index',
		meta: {
			title: '¿Cómo funciona?'
		},
		component: () => import('../views/como-funciona/Index.vue')
	},
	{
		path: '/proveedores',
		name: 'proveedores.index',
		meta: {
			title: 'Proveedores'
		},
		component: () => import('../views/proveedores/Index.vue')
	},
	{
		path: '/mesas/crear', 
		name: 'mesas.create',
		component: () => import('../views/mesas/Create.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/mi-celebracion',
		component: () => import('../views/boda/Index.vue'),
		children: [{
			path: '',
			name: 'boda.index',
			component: () => import('../views/boda/mesa/Index.vue'),
			meta: {
				requiresAuth: true
			}
		},{
			path: '',
			name: 'boda.otorgados',
			component: () => import('../views/boda/otorgados/Index.vue'),
			meta: {
				requiresAuth: true
			}
		}
		,{
			path: '/miboda/invitados',
			name: 'boda.invitados',
			component: () => import('../views/boda/invitados/Index.vue'),
			meta: {
				requiresAuth: true
			}
		}]
	},
	{
		path: '/tienda',
		component: () => import('../views/Component.vue'),
		children: [{
			path: '',
			name: 'productos.index',
			component: () => import('../views/productos/Index.vue'),
			meta: {
				title: 'Tienda'
			},
		},
		{
			path: '/tienda/:id',
			name: 'productos.show',
			component: () => import('../views/productos/Show.vue'),
			meta: {
				title: 'Ver producto'
			},
		}]
	},

	{
		path: '/celebraciones',
		component: () => import('../views/Component.vue'),
		children: [{
			path: '/celebraciones/15',
			name: '15años.index',
			component: () => import('../views/Celebraciones/15años.vue'),
			meta: {
				title: '15 Años'
			},
		},
		{
			path: '/celebraciones/Shower',
			name: 'Babyshower.index',
			component: () => import('../views/Celebraciones/Babyshower.vue'),
			meta: {
				title: 'Baby Shower'
			},
		}]
		
	},


	{
		path: '/celebraciones',
		component: () => import('../views/Component.vue'),
		children: [{
			path: '/celebraciones/Bautizo',
			name: 'Bautizo.index',
			component: () => import('../views/Celebraciones/Bautizo.vue'),
			meta: {
				title: 'Bautizo'
			},
		},
		{
			path: '/celebraciones/Boda',
			name: 'Boda.index',
			component: () => import('../views/Celebraciones/Boda.vue'),
			meta: {
				title: 'Boda'
			},
		}]
		
	},

	{
		path: '/celebraciones',
		component: () => import('../views/Component.vue'),
		children: [{
			path: '/celebraciones/cumpleaños',
			name: 'cumpleaños.index',
			component: () => import('../views/Celebraciones/cumpleaños.vue'),
			meta: {
				title: 'Cumpleaños'
			},
		},
		{
			path: '/celebraciones/Graduacion',
			name: 'Graduacion.index',
			component: () => import('../views/Celebraciones/Graduacion.vue'),
			meta: {
				title: 'Graduación'
			},
		}]
		
	},


	{
		path: '/celebraciones',
		component: () => import('../views/Component.vue'),
		children: [{
			path: '/celebraciones/revelacion',
			name: 'revelacionbebe.index',
			component: () => import('../views/Celebraciones/revelacionbebe.vue'),
			meta: {
				title: 'Revelación Bebe'
			},
		}]
		
	},








	{
		path: '/productos',
		name: 'productos.info',
		meta: {
			title: 'Productos'
		},
		component: () => import('../views/productos/Info.vue'),
	},
	{
		path: '/:id',
		name: 'mesas.show',
		component: () => import('../views/mesas/Show.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/:slug/productos/:id',
		name: 'productos.mesas.show',
		component: () => import('../views/productos/Show.vue'),
		meta:{
			requiresAuth: true
		}
	},
	{ path: '/contacto', name: 'contacto', component: () => import('../views/Contacto.vue') },
	{ 
		path: '/compras', 
		name: 'cart', 
		component: () => import('../views/compras/Cart.vue'),
		meta:{
			requiresAuth: true
		}
	},
	{
		path: '/admin',
		component: () => import('../views/admin/Index.vue'),
		children: [{
			path: '',
			//name: 'admin.index',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.index',
					component: () => import('../views/admin/productos/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Productos'
					}
				}, {
					path: '/admin/productos/create',
					name: 'admin.create',
					component: () => import('../views/admin/productos/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar producto'
					}
				}, {
					path: '/admin/productos/:id/edit',
					name: 'admin.edit',
					component: () => import('../views/admin/productos/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar producto'
					}
				}, {
					path: '/admin/productos/:id/celebraciones',
					name: 'admin.celebraciones',
					component: () => import('../views/admin/productos/AsignarCelebracion.vue'),
					meta: {
						requiresAuth: true,
						title: 'Celebraciones'
					}
				},
			]
		}, {
			path: '/admin/productos/grupos',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.productos.grupos.index',
					component: () => import('../views/admin/productos/grupos/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Grupos de productos'
					}
				}, {
					path: '/admin/productos/grupos/create',
					name: 'admin.productos.grupos.create',
					component: () => import('../views/admin/productos/grupos/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar grupo'
					}
				}, {
					path: '/admin/productos/grupos/:id/edit',
					name: 'admin.productos.grupos.edit',
					component: () => import('../views/admin/productos/grupos/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar grupo'
					}
				},
			]
		}, {
			path: '/admin/productos/tipos',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.productos.tipos.index',
					component: () => import('../views/admin/productos/tipos/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Tipos de productos'
					}
				}, {
					path: '/admin/productos/tipos/create',
					name: 'admin.productos.tipos.create',
					component: () => import('../views/admin/productos/tipos/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar tipo'
					}
				}, {
					path: '/admin/productos/tipos/:id/edit',
					name: 'admin.productos.tipos.edit',
					component: () => import('../views/admin/productos/tipos/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar tipo'
					}
				},
			]
		}, {
			path: '/admin/productos/marcas',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.productos.marcas.index',
					component: () => import('../views/admin/productos/marcas/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Marcas de productos'
					}
				}, {
					path: '/admin/productos/marcas/create',
					name: 'admin.productos.marcas.create',
					component: () => import('../views/admin/productos/marcas/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar marca'
					}
				}, {
					path: '/admin/productos/marcas/:id/edit',
					name: 'admin.productos.marcas.edit',
					component: () => import('../views/admin/productos/marcas/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar marca'
					}
				},
			]
		}, {
			path: '/admin/paises',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.paises.index',
					component: () => import('../views/admin/paises/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Paises'
					}
				}, {
					path: '/admin/paises/create',
					name: 'admin.paises.create',
					component: () => import('../views/admin/paises/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar país'
					}
				}, {
					path: '/admin/paises/:id/edit',
					name: 'admin.paises.edit',
					component: () => import('../views/admin/paises/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar país'
					}
				},
			]
		}, {
			path: '/admin/proveedores',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.proveedores.index',
					component: () => import('../views/admin/proveedores/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Proveedores'
					}
				}, {
					path: '/admin/proveedores/create',
					name: 'admin.proveedores.create',
					component: () => import('../views/admin/proveedores/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar proveedor'
					}
				}, {
					path: '/admin/proveedores/:id/edit',
					name: 'admin.proveedores.edit',
					component: () => import('../views/admin/proveedores/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar proveedor'
					}
				},
			]
		}, {
			path: '/admin/proveedores/tipos',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.proveedores.tipos.index',
					component: () => import('../views/admin/proveedores/tipos/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Tipos de proveedores'
					}
				}, {
					path: '/admin/proveedores/tipos/create',
					name: 'admin.proveedores.tipos.create',
					component: () => import('../views/admin/proveedores/tipos/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar tipo'
					}
				}, {
					path: '/admin/proveedores/tipos/:id/edit',
					name: 'admin.proveedores.tipos.edit',
					component: () => import('../views/admin/proveedores/tipos/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar tipo'
					}
				},
			]
		},
		{
			path: '/admin/celebraciones',
			component: () => import('../views/Component.vue'),
			children:[{
					path: '',
					name: 'admin.celebraciones.index',
					component: () => import('../views/admin/celebraciones/Index.vue'),
					meta: {
						requiresAuth: true,
						title: 'Celebraciones'
					}
				}, {
					path: '/admin/celebraciones/create',
					name: 'admin.celebraciones.create',
					component: () => import('../views/admin/celebraciones/Create.vue'),
					meta: {
						requiresAuth: true,
						title: 'Agregar celebracion'
					}
				}, {
					path: '/admin/celebraciones/:id/edit',
					name: 'admin.celebraciones.edit',
					component: () => import('../views/admin/celebraciones/Edit.vue'),
					meta: {
						requiresAuth: true,
						title: 'Editar celebracion'
					}
				},
			]
		}]
	},
]

export default function() {
	return createRouter({ 
		routes,
		history,
		scrollBehavior() {
			// always scroll to top
			return { top: 0 }
		},
	})
}