import { createStore } from 'vuex'
import { getTokenApi, getItems } from "./helpers"
const Cookies = require('js-cookie')

//import {getTokenApi, getItems} from "./helpers"
export default function (axios) {
  const token = getTokenApi(Cookies)
  const items = getItems(Cookies)
  //const itemsStorage = getItems()
  if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = process.env.NODE_SERVER_PROXY || 'https://crmeasysale.com/mesa/api/';
  } else {
    axios.defaults.baseURL = process.env.NODE_SERVER_PROXY || 'http://localhost:8092/';
  }

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  return createStore({
    state: {
      errors: {},
      loading: false,
      isLoggedIn: !!token,
      currentUser: {},
      token: token,
      items: items,

      mobileMenu: false,
      windowWidth: window.innerWidth || 0,
      showMenu: true,
      bgFooter: '',
      navStatic: '',

      bodas: [],
      boda: {},
      celebraciones: {},
      productos: {},
      productosGrupos: {},
      productosTipos: {},
      productosMarcas: {},
      paises: {},
      proveedores: {},
      proveedoresTipos: {},
      mesas: {},
      invitados: {},
      listCelebraciones: [],
    },
    getters: {
      loading(state) {
        return state.loading
      },

      currentUser(state) {
        return state.currentUser
      },

      isLoggedIn(state) {
        return state.isLoggedIn
      },

      errors(state) {
        return state.errors
      },

      token(state) {
        return state.token
      },

      mobileMenu(state) {
        return state.mobileMenu
      },
      windowWidth(state) {
        return state.windowWidth
      },
      mobile(state) {
        if (state.windowWidth < 992) {
          return true
        }
        return false
      },
      navStatic(state) {
        return state.navStatic
      },
      grid(state) {
        if (state.windowWidth > 576 && state.windowWidth < 768) {
          return 'sm'
        } else if (state.windowWidth >= 768 && state.windowWidth < 992) {
          return 'md'
        } else if (state.windowWidth >= 992 && state.windowWidth < 1200) {
          return 'lg'
        } else if (state.windowWidth >= 1200 && state.windowWidth < 1400) {
          return 'xl'
        } else if (state.windowWidth >= 1400) {
          return 'xxl'
        } else {
          return 'xs'
        }
      },
      showMenu(state) {
        return state.showMenu
      },
      bgFooter(state) {
        return state.bgFooter
      },
      paises(state) {
        return state.paises
      },
      proveedores(state) {
        return state.proveedores
      },
      proveedoresTipos(state) {
        return state.proveedoresTipos
      },
      bodas(state) {
        return state.bodas
      },
      boda(state) {
        return state.boda
      },
      celebraciones(state) {
        return state.celebraciones
      },
      productos(state) {
        return state.productos
      },
      productosGrupos(state) {
        return state.productosGrupos
      },
      productosTipos(state) {
        return state.productosTipos
      },
      productosMarcas(state) {
        return state.productosMarcas
      },
      mesas(state) {
        return state.mesas
      },
      invitados(state) {
        return state.invitados
      },
      items(state) {
        return state.items
      },

      listCelebraciones(state) {
        return state.listCelebraciones
      }
    },
    mutations: {
      errors(state, payload) {
        state.errors = payload
      },
      showLoading(state) {
        state.loading = true
      },
      hideLoading(state) {
        state.loading = false
      },

      currentUser(state, payload) {
        state.currentUser = payload
      },
      header(state, payload) {
        state.header = payload
      },

      loginSuccess(state, payload) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${payload.API_TOKEN}`
        Cookies.set('token', payload.API_TOKEN, { sameSite: 'Strict' })
        //localStorage.setItem("token", payload.API_TOKEN)
        state.token = payload.API_TOKEN
        state.errors = null
        state.isLoggedIn = true
        state.currentUser = payload
      },

      logout(state) {
        Cookies.remove('token')
        //localStorage.removeItem("token")
        state.token = null
        state.errors = null
        state.isLoggedIn = false
        state.currentUser = {}
      },

      mobileMenu(state, payload) {
        state.mobileMenu = payload
      },
      windowWidth(state, payload) {
        state.windowWidth = payload
      },
      showMenu(state, payload) {
        state.showMenu = payload
      },
      navStatic(state, payload) {
        state.navStatic = payload
      },
      bgFooter(state, payload) {
        state.bgFooter = payload
      },

      paises(state, payload) {
        if (state.paises.current_page && state.paises.current_page + 1 == payload.current_page) {
          state.paises = {
            current_page: payload.current_page,
            data: state.paises.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.paises = payload
        }
      },
      destroyPaises(state, payload) {
        if (state.paises) {
          if (state.paises.data) {
            const index = state.paises.data.findIndex(x => x.CODIGO_PAIS === payload)
            state.paises.data.splice(index, 1)
          }
        }
      },
      proveedores(state, payload) {
        if (state.proveedores.current_page && state.proveedores.current_page + 1 == payload.current_page) {
          state.proveedores = {
            current_page: payload.current_page,
            data: state.proveedores.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.proveedores = payload
        }
      },
      destroyProveedores(state, payload) {
        if (state.proveedores) {
          if (state.proveedores.data) {
            const index = state.proveedores.data.findIndex(x => x.CODIGO_PROVEEDOR === payload)
            state.proveedores.data.splice(index, 1)
          }
        }
      },

      proveedoresTipos(state, payload) {
        if (state.proveedoresTipos.current_page && state.proveedoresTipos.current_page + 1 == payload.current_page) {
          state.proveedoresTipos = {
            current_page: payload.current_page,
            data: state.proveedoresTipos.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.proveedoresTipos = payload
        }
      },
      destroyProveedoresTipos(state, payload) {
        if (state.proveedoresTipos) {
          if (state.proveedoresTipos.data) {
            const index = state.proveedoresTipos.data.findIndex(x => x.CODIGO_PROVEEDOR_TIPO === payload)
            state.proveedoresTipos.data.splice(index, 1)
          }
        }
      },

      bodas(state, payload) {
        state.bodas = payload
      },
      boda(state, payload) {
        state.boda = payload
      },

      listCelebraciones(state, payload){
        state.listCelebraciones = payload
      },

      celebraciones(state, payload) {
        if (state.celebraciones.current_page && state.celebraciones.current_page + 1 == payload.current_page) {
          state.celebraciones = {
            current_page: payload.current_page,
            data: state.celebraciones.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.celebraciones = payload
        }
      },
      destroyCelebraciones(state, payload) {
        if (state.celebraciones) {
          if (state.celebraciones.data) {
            const index = state.celebraciones.data.findIndex(x => x.CODIGO_CELEBRACION === payload)
            state.celebraciones.data.splice(index, 1)
          }
        }
      },

      productos(state, payload) {
        if (state.productos.current_page && state.productos.current_page + 1 == payload.current_page) {
          state.productos = {
            current_page: payload.current_page,
            data: state.productos.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.productos = payload
        }
      },
      destroyProductos(state, payload) {
        if (state.productos) {
          if (state.productos.data) {
            const index = state.productos.data.findIndex(x => x.CODIGO_PRODUCTO_GRUPO === payload)
            state.productos.data.splice(index, 1)
          }
        }
      },

      productosGrupos(state, payload) {
        if (state.productosGrupos.current_page && state.productosGrupos.current_page + 1 == payload.current_page) {
          state.productosGrupos = {
            current_page: payload.current_page,
            data: state.productosGrupos.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.productosGrupos = payload
        }
      },
      destroyProductosGrupos(state, payload) {
        if (state.productosGrupos) {
          if (state.productosGrupos.data) {
            const index = state.productosGrupos.data.findIndex(x => x.CODIGO_PRODUCTO_GRUPO === payload)
            state.productosGrupos.data.splice(index, 1)
          }
        }
      },

      productosTipos(state, payload) {
        if (state.productosTipos.current_page && state.productosTipos.current_page + 1 == payload.current_page) {
          state.productosTipos = {
            current_page: payload.current_page,
            data: state.productosTipos.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.productosTipos = payload
        }
      },
      destroyProductosTipos(state, payload) {
        if (state.productosTipos) {
          if (state.productosTipos.data) {
            const index = state.productosTipos.data.findIndex(x => x.CODIGO_PRODUCTO_TIPO === payload)
            state.productosTipos.data.splice(index, 1)
          }
        }
      },

      productosMarcas(state, payload) {
        if (state.productosMarcas.current_page && state.productosMarcas.current_page + 1 == payload.current_page) {
          state.productosMarcas = {
            current_page: payload.current_page,
            data: state.productosMarcas.data.concat(payload.data),
            first_page_url: payload.first_page_url,
            from: payload.from,
            last_page: payload.last_page,
            last_page_url: payload.last_page_url,
            links: payload.links,
            next_page_url: payload.next_page_url,
            path: payload.path,
            per_page: payload.per_page,
            prev_page_url: payload.prev_page_url,
            to: payload.to,
            total: payload.total
          }
        } else {
          state.productosMarcas = payload
        }
      },
      destroyProductosMarcas(state, payload) {
        if (state.productosMarcas) {
          if (state.productosMarcas.data) {
            const index = state.productosMarcas.data.findIndex(x => x.CODIGO_MARCA === payload)
            state.productosMarcas.data.splice(index, 1)
          }
        }
      },
      mesas(state, payload) {
        state.mesas = payload
      },
      deleteMesa(state, payload) {
        if (state.mesas) {
          if (state.mesas.data) {
            const index = state.mesas.data.findIndex(x => x.CODIGO_BODA_MESA === payload)
            state.mesas.data.splice(index, 1)
          }
        }
      },
      invitados(state, payload) {
        state.invitados = payload
      },
      deleteInvitado(state, payload) {
        if (state.invitados) {
          if (state.invitados.data) {
            const index = state.invitados.data.findIndex(x => x.CODIGO_INVITADO === payload)
            if (index >= 0) {
              state.invitados.data.splice(index, 1)
            }
          }
        }
      },
      updateInvitado(state, payload) {
        if (state.invitados) {
          if (state.invitados.data) {
            const index = state.invitados.data.findIndex(x => x.CODIGO_INVITADO === payload.CODIGO_INVITADO)
            if (index >= 0) {
              state.invitados.data.splice(index, 1, payload)
            }
          }
        }
      },
      addItems(state, payload) {
        state.items = payload || []
        Cookies.set('items', JSON.stringify(state.items), { sameSite: 'Strict' })
      },
      addItem(state, payload) {
        if (state.items.length > 0 && (state.items[0].CODIGO_BODA != payload.CODIGO_BODA)) {
          state.items = []
        }

        const index = state.items.findIndex(x => x.CODIGO_BODA_MESA === payload.CODIGO_BODA_MESA)
        if (index >= 0) {
          state.items.splice(index, 1, payload)
        } else {
          state.items.push(payload)
        }
        Cookies.set('items', JSON.stringify(state.items), { sameSite: 'Strict' })
      },
      deleteItem(state, payload) {
        const index = state.items.findIndex(x => x.CODIGO_BODA_MESA === payload.CODIGO_BODA_MESA)
        if (index >= 0) {
          state.items.splice(index, 1)
        }
        Cookies.set('items', JSON.stringify(state.items), { sameSite: 'Strict' })
      },
      deleteAllItems(state) {
        state.items = []
        Cookies.set('items', JSON.stringify(state.items), { sameSite: 'Strict' })
      }
    },
    actions: {
      getUsuario(context, params = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = params.url || '/usuario'
          delete params['url']
          axios.post(url, params).then(res => {
            context.commit("currentUser", res.data)
            resolve(res)
          })
            .catch(error => {
              reject(error)
            }).then(() => {
              context.commit('hideLoading')
            })
        })
      },

      login(context, usuario) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/auth/token'

          axios.post(url, usuario).then(res => {
            context.commit('loginSuccess', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      socialLogin(context, usuario) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/auth/login/${usuario.provider}/callback`

          axios.post(url, usuario).then(res => {
            context.commit('loginSuccess', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      register(context, usuario) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/auth/register'

          axios.post(url, usuario).then(res => {
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      recovery(context, usuario) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/auth/recovery'

          axios.post(url, usuario).then(res => {
            //context.commit('loginSuccess', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      recoveryReset(context, usuario) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/auth/recovery/reset'

          axios.post(url, usuario).then(res => {
            //context.commit('loginSuccess', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getPaises(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/paises'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('paises', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storePaises(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/paises/store'

          axios.post(url, request).then(res => {
            //context.commit('paises', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showPaises(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/paises/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getPaises')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updatePaises(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/paises/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getPaises')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deletePaises(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/paises/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyPaises', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getProveedores(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/proveedores'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('proveedores', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProveedores(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/proveedores/store'

          axios.post(url, request).then(res => {
            //context.commit('proveedores', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProveedores(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProveedores')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProveedores(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProveedores')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProveedores(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProveedores', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getProveedoresTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/proveedores/tipos'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('proveedoresTipos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProveedoresTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/proveedores/tipos/store'

          axios.post(url, request).then(res => {
            //context.commit('proveedoresTipos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProveedoresTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/tipos/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProveedoresTipos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProveedoresTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/tipos/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProveedoresTipos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProveedoresTipos(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/proveedores/tipos/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProveedoresTipos', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getBodas(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/bodas'

          axios.post(url, filters).then(res => {
            context.commit('bodas', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      addBoda(context, boda) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/bodas/store'

          axios.post(url, boda).then(res => {
            //context.commit('loginSuccess', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getBoda(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/bodas/${filters.codigo}/show`

          axios.post(url, filters).then(res => {
            context.commit('boda', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getCelebraciones(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/celebraciones'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('celebraciones', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeCelebraciones(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/celebraciones/store'

          axios.post(url, request).then(res => {
            //context.commit('celebraciones', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showCelebraciones(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/celebraciones/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getCelebraciones')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateCelebraciones(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/celebraciones/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getCelebraciones')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteCelebraciones(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/celebraciones/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyCelebraciones', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getCatalogosCelebraciones(context) {
        return new Promise((resolve, reject) => {
            var url = '/catalogos/celebraciones'
            axios.get(url).then(response => {
                    context.commit("listCelebraciones", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                }).then(() => {
                    context.commit('hideLoading')
                })
        })
    },

      addProductoCelebracion(context, data) {
        return new Promise((resolve, reject) => {
          var url = '/productos/celebraciones/add'
          axios.post(url, data).then(response => {
            context.dispatch('getProductos')
            resolve(response)
          })
            .catch(error => {
              reject(error)
            }).then(() => {
              context.commit('hideLoading')
            })
        })
      },

      removeProductoCelebracion(context, data) {
        return new Promise((resolve, reject) => {
          var url = '/productos/celebraciones/remove'
          axios.post(url, data).then(response => {
            context.dispatch('getProductos')
            resolve(response)
          })
            .catch(error => {
              reject(error)
            }).then(() => {
              context.commit('hideLoading')
            })
        })
      },

      getProductos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/productos'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('productos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProductos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/productos/store'

          axios.post(url, request).then(res => {
            //context.commit('productos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProducto1(context, producto) {
        return new Promise((resolve, reject) => {
            context.commit('showLoading')
            axios.get(`/productos/${producto}/show`)
                .then(response => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                }).then(() => {
                    context.commit('hideLoading')
                })
        })
    },

      showProductos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProductos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProductos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProductos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProductos(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProductos', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getProductosGrupos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/productos/grupos'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('productosGrupos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProductosGrupos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/productos/grupos/store'

          axios.post(url, request).then(res => {
            //context.commit('productosGrupos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProductosGrupos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/grupos/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProductosGrupos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProductosGrupos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/grupos/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProductosGrupos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProductosGrupos(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/grupos/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProductosGrupos', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getProductosTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/productos/tipos'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('productosTipos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProductosTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/productos/tipos/store'

          axios.post(url, request).then(res => {
            //context.commit('productosTipos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProductosTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/tipos/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProductosTipos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProductosTipos(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/tipos/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProductosTipos')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProductosTipos(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/tipos/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProductosTipos', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getProductosMarcas(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = request.url || '/productos/marcas'
          delete request['url']
          axios.post(url, request).then(res => {
            context.commit('productosMarcas', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeProductosMarcas(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/productos/marcas/store'

          axios.post(url, request).then(res => {
            //context.commit('productosMarcas', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      showProductosMarcas(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/marcas/${request.id}/show`

          axios.post(url, request).then(res => {
            //context.dispatch('getProductosMarcas')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateProductosMarcas(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/marcas/${request.id}/update`

          axios.put(url, request).then(res => {
            context.dispatch('getProductosMarcas')
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteProductosMarcas(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/productos/marcas/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('destroyProductosMarcas', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getMesas(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/mesas'

          axios.post(url, filters).then(res => {
            context.commit('mesas', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      addMesas(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/mesas/store'

          axios.post(url, filters).then(res => {
            //context.commit('productos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteMesas(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/mesas/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('deleteMesa', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      getInvitados(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/invitados'

          axios.post(url, filters).then(res => {
            context.commit('invitados', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      addInvitados(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/invitados/store'

          axios.post(url, filters).then(res => {
            //context.commit('productos', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      updateInvitados(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/invitados/${filters.id}/update`

          axios.put(url, filters).then(res => {
            context.commit('updateInvitado', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      deleteInvitados(context, filters) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = `/invitados/${filters.id}`

          axios.delete(url, filters).then(res => {
            context.commit('deleteInvitado', filters.id)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      mesasInvitadosStore(context, request = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = '/mesas/invitados/store'

          axios.post(url, request).then(res => {
            //context.commit('productosMarcas', res.data)
            resolve(res)
          }).catch(err => {
            if (err.response) {
              if (err.response.status == 422) {
                context.commit('errors', err.response.data)
              }
            }
            reject(err)
          }).then(() => {
            context.commit('hideLoading')
          })
        })
      },

      storeAdjunto(context, params = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          var url = params.url || '/adjuntos/store'
          delete params['url']
          axios.post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            /*onUploadProgress: function(e) {
              context.dispatch("getTiposPrendas")
              this.files[this.fileUpload].progress = (e.loaded / e.total) * 100
            }.bind(this)*/
          }).then(res => {
            resolve(res)
          })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 422) {
                  context.commit('errors', error.response.data)
                }
              }
              reject(error)
            }).then(() => {
              context.commit('hideLoading')
            })
        })
      },

      deleteAdjunto(context, id, params = {}) {
        return new Promise((resolve, reject) => {
          context.commit('showLoading')
          context.commit('errors', {})
          axios.delete(`/adjuntos/${id}`, { data: params })
            .then(response => {
              resolve(response)
              //context.dispatch('get')
            }).catch(error => {
              console.log(error)
              console.log(error.response)
              reject(error)
            }).then(() => {
              context.commit('hideLoading')
            })
        })
      },

      getCountry() {
        return new Promise((resolve, reject) => {
          var url = 'https://ipinfo.io/?token=062511e2fba6e1'
          axios.get(url).then(res => {
            resolve(res)
          })
            .catch(error => {
              reject(error)
            })
        })
      },
    },
    modules: {
    }
  })
}
