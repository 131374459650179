<template>
    <div class="bg-light position-relative">
        <img :src="imgBorder" class="img-fluid position-absolute" style="left: 0px; right: 0px; top: 0px;">
        <div class="form-row p-3 p-sm-5">
            <div class="col-lg-2">
                <router-link :to="{name: 'home'}">
                    <img :src="LogoDark" alt="Logo" class="img-fluid mb-3">
                </router-link>
                
                <a href="#" class="d-block mb-2 text-primary">Sobre nosotros</a>
                <a href="#" class="d-block mb-2 text-primary">Blog</a>
                <div class="d-flex">
                    <a href="#" class="d-flex align-items-center justify-content-center mx-2 text-primary">
                        <font-awesome-icon :icon="faFacebookF" style="font-size: 30px;" />
                    </a>

                    <a href="#" class="d-flex align-items-center justify-content-center mx-2 text-primary" v-if="false">
                        <font-awesome-icon :icon="faTwitter" style="font-size: 30px;" />
                    </a>

                    <a href="#" class="d-flex align-items-center justify-content-center mx-2 text-primary">
                        <font-awesome-icon :icon="faInstagram" style="font-size: 30px;" />
                    </a>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row mx-3">
                    <div class="col-lg-4">
                        <div v-if="false">
                            <h3>Accesos</h3>
                            <router-link :to="{name: 'login'}" class="d-block mb-2">
                                Acceder
                            </router-link>
                            <a href="#" class="d-block mb-2">
                                Beneficios
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div v-if="false">
                            <h3>Servicios</h3>
                            <router-link :to="{name: 'mesas.create'}" class="d-block mb-2">
                                Crea una mesa
                            </router-link>
                            <router-link :to="{name: 'mesas.index'}" class="d-block mb-2">
                                Encuentra una mesa
                            </router-link>
                            <a href="#" class="d-block mb-2">
                                Productos
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <h3 class="text-primary">Ayuda</h3>
                        <a href="mailto:hola@mesaregalo.com" class="d-block mb-2 text-secondary">
                            hola@mesaregalo.com
                        </a>
                        <a href="https://wa.me/50255553333?text=Me+interesa+recibir+informaci%C3%B3n" target="_blank" class="d-block mb-2 text-secondary">
                            WhatsApp 5555-3333
                        </a>
                        <router-link :to="{name: 'contacto'}" class="d-block mb-2 text-secondary">
                            Contacto
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <h3 class="text-primary">Mantente informado</h3>
                <form>
                    <div class="position-relative mb-3">
                        <input 
                            name="correo"
                            type="mail" 
                            class="form-control form-control-lg"
                            style="padding-right: 130px;" 
                            placeholder="Ingresa tu correo">
                        <div class="position-absolute" style="right: 5px; top: 5px;">
                            <button class="btn btn-primary" type="submit">
                                <font-awesome-icon :icon="faPaperPlane" />
                                Suscríbete
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div :class="[bgFooter, {'p-3':true}]">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div class="text-center text-md-start">
                    Made with <font-awesome-icon :icon="faCoffee" /> & <font-awesome-icon :icon="faHeart" /> by <a href="https://www.itligencia.com" target="_blank" class="text-white">Itligencia</a>
                </div>
                <div class="text-center text-md-start">
                    Copyright © 2021 Mesa de regalo | Todos los derechos reservados
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import imgBorder from '@/assets/img/border.jpg'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faHeart, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import LogoDark from '@/assets/img/logo_dark.png'
export default {
    data(){
        return {
            imgBorder,
            LogoDark,
            faFacebookF,
            faTwitter,
            faInstagram,
            faCoffee,
            faHeart,
            faPaperPlane
        }
    },
    computed: {
        showMenu(){
            return this.$store.getters.showMenu
        },
        bgFooter(){
            return this.$store.getters.bgFooter || 'bg-primary text-white'
        }
    },
    components: {
        FontAwesomeIcon
    }
}
</script>