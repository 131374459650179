<template>
    <div class="position-fixed top-0 bottom-0 start-0 end-0 bg-white vh-100 d-flex flex-column">
        <div class="d-flex justify-content-between px-3 py-2 border-bottom">
            <button type="button" class="btn btn-link text-dark text-decoration-none rounded-pill" v-if="title" v-on:click="setMenu(-1)" style="font-size: 20px;">
                <font-awesome-icon :icon="faArrowLeft" class="me-2" />{{title}}
            </button>
            <router-link :to="{name: 'home'}" class="navbar-brand" v-else v-on:click="hideMobileMenu">
                <img :src="LogoDark" alt="Logo" height="15">
            </router-link>
            <div>
                <button type="button" class="btn btn-link text-dark text-decoration-none rounded-circle align-middle me-2" style="font-size: 20px;">
                    <span class="ei-search"></span>
                </button>
                <button type="button" class="btn btn-link text-dark text-decoration-none rounded-circle" v-on:click="hideMobileMenu" style="font-size: 20px;">
                    <font-awesome-icon :icon="faTimes" />
                </button>
            </div>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(menu, index) in menusFiltered" :key="index">
                <router-link :to="{name: menu.ruta}" class="d-flex text-dark text-decoration-none" v-if="menu.ruta" v-on:click="hideMobileMenu">
                    <div class="me-2 d-flex align-items-center" v-if="menu.icono">
                        <font-awesome-icon :icon="menu.icono" />
                    </div>
                    <div class="w-100 p-2">
                        <div :class="{'d-flex justify-content-between align-items-center':menu.children && menu.children.length>0}">
                            <p v-text="menu.titulo" class="font-weight-bold mb-0"></p>
                            <font-awesome-icon :icon="faChevronRight" v-if="menu.children && menu.children.length>0" />
                        </div>
                        <p v-text="menu.descripcion" v-if="menu.descripcion"></p>
                    </div>
                </router-link>
                <a href="#" class="d-flex text-dark text-decoration-none" v-else v-on:click.prevent="setMenu(index)">
                    <div class="me-2" v-if="menu.icono">
                        {{menu.icono}}
                    </div>
                    <div class="w-100 p-2">
                        <div :class="{'d-flex justify-content-between align-items-center':menu.children && menu.children.length>0}">
                            <p v-text="menu.titulo" class="font-weight-bold mb-0"></p>
                            <font-awesome-icon :icon="faChevronRight" v-if="menu.children && menu.children.length>0" />
                        </div>
                        <p v-text="menu.descripcion" v-if="menu.descripcion"></p>
                    </div>
                </a>
            </li>
        </ul>
        <div class="d-flex justify-content-between p-3 mt-auto border-top">
            <div class="w-50 px-1">
                <router-link :to="{name: 'logout'}" class="btn btn-outline-primary btn-lg w-100" v-on:click="hideMobileMenu" v-if="isLoggedIn">
                    Salir
                </router-link>
                <router-link :to="{name: 'login'}" class="btn btn-outline-primary btn-lg w-100" v-on:click="hideMobileMenu" v-else>
                    Acceder
                </router-link>
            </div>
            <div class="w-50 px-1" v-if="!user.CODIGO_BODA">
                <router-link :to="{name: 'mesas.create'}" class="btn btn-primary btn-lg w-100" v-on:click="hideMobileMenu">
                    Crea tu mesa
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import LogoDark from '@/assets/img/logo_dark.png'
export default {
    name: 'MenuMobile',
    props: ['menus'],
    components: {
        FontAwesomeIcon,
    },
    data(){
        return {
            indexMenu: -1,
            faChevronRight,
            faTimes,
            faArrowLeft,
            LogoDark
        }
    },
    methods: {
        setMenu(index){
            if(index==-1){
                this.indexMenu=-1
            }else{
                if(this.menus[index].children && this.menus[index].children.length>0){
                    this.indexMenu=index
                }
            }
        },
        hideMobileMenu(){
            this.$store.commit('mobileMenu', false)
        }
    },
    computed: {
        menusFiltered(){
            if(this.indexMenu==-1){
                if(!this.isLoggedIn){
                    return this.menus.filter(x => !x.auth)
                }
                return this.menus
            }
            return this.menus[this.indexMenu].children
        },
        title(){
            if(this.indexMenu==-1){
                return ''
            }
            return this.menus[this.indexMenu].titulo
        },
        isLoggedIn(){
            return this.$store.getters.isLoggedIn
        },
        user(){
            return this.$store.getters.currentUser || {}
        },
    },
    watch: {
        '$route' (to, from) {
            //this.hideMobileMenu()
            return {to, from}
        }
    }
}
</script>