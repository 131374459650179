<template>
    <nav :class="[navStatic, {'navbar navbar-expand-lg fixed-top py-2':true, 'bg-white navbar-light':!bgNavTransparent && showMenu, 'navbar-dark bg-transparent':bgNavTransparent && showMenu, 'bg-white':!showMenu}]">
        <div class="container">
            <router-link :to="{name: 'home'}" class="navbar-brand py-0">
                <img :src="Logo" alt="Logo" height="78" v-if="bgNavTransparent && showMenu">
                <img :src="LogoDark" alt="Logo" height="78" v-else>
            </router-link>
            <button class="navbar-toggler" type="button" v-on:click.prevent="mobileMenuHandler">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarScroll">
                <ul class="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 150px;">
                    <li class="nav-item" v-if="!user.CODIGO_BODA && isLoggedIn">
                        <router-link :to="{name: 'mesas.create'}" class="nav-link" exact-active-class="text-primary">
                            Crea tu mesa
                        </router-link>
                    </li>

                    

                    <li :class="{'nav-item':true, 'dropdown':!menu.ruta && menu.children}" v-for="(menu, index) in menusFiltered" :key="index">
                        <router-link :to="{name: menu.ruta}" :class="{'dropdown-item':menu.children, 'nav-link':!menu.children}" v-if="menu.ruta" exact-active-class="text-primary">
                            {{menu.titulo}}
                        </router-link>
                        <a :class="{'nav-link':true, 'dropdown-toggle':!menu.ruta && menu.children}" aria-current="page" href="#" :id="'navbarScrollingDropdownMesaRegalo'+index" role="button" data-bs-toggle="dropdown" aria-expanded="false" v-else>
                            {{menu.titulo}}
                        </a>
                        <ul class="dropdown-menu" :aria-labelledby="'navbarScrollingDropdownMesaRegalo'+index" v-if="menu.children && menu.children.length>0">
                            <li v-for="(children, indexChildren) in menu.children" :key="indexChildren">
                                <router-link :to="{name: children.ruta}" class="dropdown-item">
                                    {{children.titulo}}
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li>     
                        <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Celebraciones
                        </button>
                         <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><router-link :to="{name: '15años.index'}" class="dropdown-item">15 Años</router-link></li>
                            <li><router-link :to="{name: 'Babyshower.index'}" class="dropdown-item">Baby Shower</router-link></li>
                            <li><router-link :to="{name: 'Bautizo.index'}" class="dropdown-item">Bautizo</router-link></li>
                            <li><router-link :to="{name: 'Boda.index'}" class="dropdown-item">Boda</router-link></li>
                            <li><router-link :to="{name: 'cumpleaños.index'}" class="dropdown-item">Cumpleaños</router-link></li>
                            <li><router-link :to="{name: 'Graduacion.index'}" class="dropdown-item">Graduación</router-link></li>
                            <li><router-link :to="{name: 'revelacionbebe.index'}" class="dropdown-item">Revelación Bebe</router-link></li>
                        </ul>
                    </div>

                    </li> 


                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item" v-if="false">
                        <a href="#" class="nav-link">
                            <span class="ei-search" style="font-size: 20px;"></span>
                        </a>
                    </li>
                    <li class="nav-item position-relative">
                        <router-link :to="{name: 'cart'}" class="nav-link">
                            <span class="ei-icon_bag_alt" style="font-size: 20px;"></span>
                            <span class="badge bg-danger text-white position-absolute top-0" v-if="items.length>0" style="right: 2px;">
                                {{items.length}}
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item dropdown" v-if="user.CODIGO_USUARIO">
                        <a :class="{'btn dropdown-toggle mx-2':true, 'btn-outline-primary':!bgNavTransparent, 'btn-outline-light':bgNavTransparent}" aria-current="page" href="#" id="navbarScrollingDropdownMesaRegaloUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{user.NOMBRES}}
                            <span class="ei-icon_profile" style="font-size: 20px;"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end pt-0" aria-labelledby="navbarScrollingDropdownMesaRegaloUser" style="width: 250px">
                            <div class="bg-primary text-white p-2">
                                <div class="d-flex align-items-center">
                                    <div class="me-1">
                                        <font-awesome-icon :icon="faUserCircle" style="font-size: 30px;" />
                                    </div>
                                    <div>
                                        <h5 class="mb-0">{{user.NOMBRES}}</h5>
                                        <p class="mb-0">
                                            <small>
                                                {{user.CORREO_ELECTRONICO}}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 text-center border-bottom" v-if="user.PERFIL == 'A'">
                                    <router-link :to="{name: 'admin.index'}" class="text-muted text-decoration-none link-menu p-3 d-block">
                                        <span class="link-menu-hover">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.25" d="M2 6.5C2 4.01472 4.01472 2 6.5 2H17.5C19.9853 2 22 4.01472 22 6.5V6.5C22 8.98528 19.9853 11 17.5 11H6.5C4.01472 11 2 8.98528 2 6.5V6.5Z" fill="currentColor"></path>
                                                <path d="M20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5Z" fill="currentColor"></path>
                                                <path opacity="0.25" d="M2 17.5C2 15.0147 4.01472 13 6.5 13H17.5C19.9853 13 22 15.0147 22 17.5V17.5C22 19.9853 19.9853 22 17.5 22H6.5C4.01472 22 2 19.9853 2 17.5V17.5Z" fill="currentColor"></path>
                                                <path d="M9 17.5C9 18.8807 7.88071 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <br>
                                        <small class="text-dark">
                                            Mantenimientos
                                        </small>
                                    </router-link>
                                </div>
                                <div class="col-6 text-center border-end">
                                    <router-link :to="{name: 'boda.index'}" class="text-muted text-decoration-none link-menu p-3 d-block" v-if="user.CODIGO_BODA">
                                        <span class="link-menu-hover">
                                            <font-awesome-icon :icon="faGifts" style="font-size: 3rem;" />
                                        </span>
                                        <br>
                                        <small class="text-dark">
                                            Ir a tu mesa
                                        </small>
                                    </router-link>
                                    <router-link :to="{name: 'mesas.create'}" class="text-muted text-decoration-none link-menu p-3 d-block" v-else>
                                        <span class="link-menu-hover">
                                            <font-awesome-icon :icon="faCartPlus" style="font-size: 3rem;" />
                                        </span>
                                        <br>
                                        <small class="text-dark">
                                            Crea tu mesa
                                        </small>
                                    </router-link>
                                </div>
                                <div class="col-6 text-center">
                                    <router-link :to="{name: 'logout'}" class="text-muted text-decoration-none link-menu p-3 d-block">
                                        <span class="link-menu-hover">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z" fill="currentColor" fill-rule="nonzero" opacity="0.5" transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000)"></path>
                                                    <rect fill="currentColor" opacity="0.5" transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000)" x="13" y="6" width="2" height="12" rx="1"></rect>
                                                    <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z" fill="currentColor" fill-rule="nonzero" transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000)"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <br>
                                        <small class="text-dark">
                                            Cerrar sesión
                                        </small>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item" v-else>
                        <router-link :to="{name: 'login'}" :class="{'btn':true, 'btn-outline-primary':!bgNavTransparent, 'btn-outline-light':bgNavTransparent}">
                            <span class="ei-icon_profile" style="font-size: 20px;"></span>
                            Acceder
                        </router-link>
                    </li>
                </ul>
            </div>
            <transition 
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp">
                <MenuMobile :menus="menus" v-if="mobileMenu && windowWidth<992"/>
            </transition>
        </div>
    </nav>
</template>
<script>
import Logo from '@/assets/img/logo_white.png'
import LogoDark from '@/assets/img/logo_dark.png'
import MenuMobile from '@/components/MenuMobile.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserCircle, faSignOutAlt, faSlidersH, faGifts, faCartPlus } from '@fortawesome/free-solid-svg-icons'

export default {
    components: {
        MenuMobile,
        FontAwesomeIcon
    },
    data(){
        return {
            Logo,
            LogoDark,
            faSignOutAlt,
            faUserCircle,
            faSlidersH,
            faGifts,
            faCartPlus,
            bgNavTransparent: true,
            menus: [
                {
                    titulo: 'Perfil',
                    auth: true,
                    movil: true,
                    children: [{
                        titulo: 'Cerrar sesión',
                        descripcion: '',
                        icono: faSignOutAlt,
                        ruta: 'logout'
                    }]
                },
                {
                    titulo: 'Encuentra una mesa',
                    descripcion: '',
                    icono: '',
                    ruta: 'mesas.index'
                },
                {
                    titulo: 'Como funciona',
                    descripcion: '',
                    icono: '',
                    ruta: 'como-funciona.index'
                },
                {
                    titulo: 'Proveedores',
                    descripcion: '',
                    icono: '',
                    ruta: 'proveedores.index'
                },
                {
                    titulo: 'Productos',
                    descripcion: '',
                    icono: '',
                    ruta: 'productos.info'
                },
                

                {
                    titulo: 'Contacto',
                    descripcion: '',
                    icono: '',
                    ruta: 'contacto'
                },


            ]
        }
    },
    mounted(){
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted(){
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        mobileMenuHandler(){
            this.$store.commit('mobileMenu', true)
        },
        handleScroll () {
            if(this.navStatic==''){
                if(document.documentElement.scrollTop>90 && this.bgNavTransparent){
                    this.bgNavTransparent = false
                }
                if(document.documentElement.scrollTop<=90 && !this.bgNavTransparent){
                    this.bgNavTransparent = true
                }
            }
        }
    },
    computed: {
        windowWidth(){
            return this.$store.getters.windowWidth || 0
        },
        mobileMenu(){
            return this.$store.getters.mobileMenu
        },
        showMenu(){
            return this.$store.getters.showMenu
        },
        user(){
            return this.$store.getters.currentUser || {}
        },
        menusFiltered(){
            return this.menus.filter(x => !x.movil)
        },
        navStatic(){
            return this.$store.getters.navStatic
        },
        items() {
            return this.$store.getters.items || []
        },
        isLoggedIn(){
            return this.$store.getters.isLoggedIn
        }
    },
    watch: {
        windowWidth(val){
            if(val>=992){
                this.$store.commit('mobileMenu', false)
            }
        },
        navStatic(val){
            if(val==''){
                this.bgNavTransparent = true
            }else{
                this.bgNavTransparent = false
            }
        }
    }
}
</script>